<template>
  <div>
    <b-card title="Registro cama paciente">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nombres"
            label-for="names"
          >
            <b-form-input
              id="names"
              v-model="names"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Apellidos"
            label-for="lastname"
          >
            <b-form-input
              id="lastname"
              v-model="lastname"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Tipos de riesgo*"
            label-for="risk"
          >
            <div class="d-flex">
              <v-select
                v-model="formData.risks"
                multiple
                label="name"
                placeholder="Seleccione riesgos"
                :options="risks"
                class="select_risk"
              >
                <div slot="no-options">
                  No hay opciones
                </div>
              </v-select>
              <b-button
                v-b-tooltip.hover.top="messageInfo"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
              >
                <feather-icon icon="AlertCircleIcon" />
              </b-button>
            </div>
          </b-form-group>

          <div
            v-for="(item, index) in formData.risks"
            :key="index"
            class="d-flex align-items-center mb-1"
          >
            <div class="title mr-1">
              {{ index + 1 }}. {{ item.name }}
            </div>
            <b-form-input
              v-if="item.typeInput == 'text'"
              v-model="item.text"
              class="w-50"
            />
            <b-form-timepicker
              v-if="item.typeInput == 'time'"
              v-model="item.text"
              locale="es"
              :hour12="false"
              class="w-50"
            /> <span
              v-if="item.typeInput == 'time'"
              class="ml-25"
            ><strong>H / m </strong></span>
            <!-- <span
              v-if="$v.formData.risks.$each[index].text.$error && !$v.formData.risks.$each[index].text.required"
              class="help-block text-danger"
            >Campo requerido</span> -->
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Médico*"
            label-for="risk"
          >
            <v-select
              v-model="formData.employee_id"
              label="title"
              placeholder="Seleccione medico"
              :options="doctors"
              :reduce="option => option.id"
              @input="loadSpecialty"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Especialidad"
            label-for="specialty"
          >
            <b-form-input
              id="specialty"
              v-model="specialty"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Registrar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BButton,
	VBTooltip,
  BFormTimepicker,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
	inject: [
		'patientsRepository',
		'risksRepository',
		'employeesRepository',
		'datafieldsRepository',
    'patientBedRepository',
	],
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BFormGroup,
		BButton,
    BFormTimepicker,
    BSpinner,
		vSelect,
	},
	directives: {
		'b-tooltip': VBTooltip,
		Ripple,
	},
	data() {
    const me = this
		return {
      isBusy: false,
      formData: {
        location_id: me.$route.params.locationId,
        employee_id: null,
        patient_id: me.$route.params.patientId,
        risks: [],
      },
			names: '',
			lastname: '',
      specialty: '',
			professionId: null,
			risks: [],
      doctors: [],
			messageInfo:
				'La persona encargada debera seleccionar los riesgos de tal forma que el primero que seleccione sea el de más prioridad y asi pasara con los demás dejando solo los 3 primeros como prioridad.',
		}
	},
  validations: {
    formData: {
      risks: {
        required,
        $each: {
          text: {
            required,
          },
        },
      },
      employee_id: {
        required,
      },
    },
  },
	async mounted() {
		const me = this
		await me.loadPatient()
		await me.loadRisks()

    const professions = await me.datafieldsRepository.getAll('professions')
    const { data } = professions
    const profesion = data.find(el => el.longName == 'Doctor')
    if (profesion) me.professionId = profesion.id

    await me.loadDoctors()
	},
	methods: {
		async loadPatient() {
			const me = this
			try {
				const res = await me.patientsRepository.find(me.formData.patient_id)
				const { data } = res.data
				me.names = data.first_name
				me.lastname = data.last_name
			} catch (error) {
				// console.log(error)
			}
		},
		async loadRisks() {
			const me = this
			try {
				const res = await me.risksRepository.getAll()
				const { data } = res
				data.forEach(item => {
          let typeInput = null
          let text = null
          if (item.name == 'Ulcera por presión') {
            typeInput = 'time'
            text = ''
          } else if (item.name == 'Alergia') {
            typeInput = 'text'
            text = ''
          } else {
            typeInput = null
            text = 'No es necesario'
          }

          me.risks.push({
            id: item.id,
            name: item.name,
            color: item.color,
            time: item.time,
            text,
            typeInput,
          })
				})
			} catch (error) {
				// console.log(error)
			}
		},
    async loadDoctors() {
      const me = this
      try {
        const res = await me.employeesRepository.getAll(me.professionId)
        const { data } = res
        me.doctors = data.map(item => ({
					id: item.id,
					title: item.full_name,
          specialty: item.specialty.name,
				}))
      } catch (error) {
        // console.log(error)
      }
    },
    loadSpecialty(id) {
      const me = this
      const doctor = me.doctors.find(el => el.id == id)
      me.specialty = doctor.specialty
    },
    async saveData() {
      const me = this
      try {
        me.isBusy = true
        const uuid = uuidv4()
        await me.patientBedRepository.save(uuid, me.formData)
        me.$router.push({ name: 'patientdashboard' })
      } catch (error) {
        const { message } = error.response.data
        me.$bvToast.toast(message, {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
      } finally {
        me.isBusy = false
      }
    },
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select_risk {
	width: 90%;
	margin-right: 10px;
}

.title {
	font-size: 14px;
	font-weight: 700;
	color: #636363;
}

.select__ulcera {
  width: 40%
}
</style>
